import React from 'react';

import WinXP from 'WinXP';
import base64 from 'base64-js';
import { useGA } from 'hooks';

function Base64Encode(str, encoding = 'utf-8') {
  // eslint-disable-next-line no-undef
  const bytes = new (TextEncoder || TextEncoderLite)(encoding).encode(str);
  return base64.fromByteArray(bytes);
}

const App = () => {
  useGA('', 'winXP');
  let isAdmin = false;
  if (!window.location.search.includes(Base64Encode('admin'))) {
    if (!window.location.search.includes(Base64Encode('visitor')))
      setTimeout(() => {
        window.location.replace(`?auth=${Base64Encode('visitor')}`);
      }, 5000);
  } else {
    isAdmin = true;
  }
  return <WinXP isAdmin={isAdmin} />;
};

export default App;
